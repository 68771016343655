const contact = {
    title: 'Hilfe erhalten',
    description: 'Für Hilfsanfragen oder Produktfeedback wenden Sie sich bitte über die folgenden Methoden an uns.',
    discord: {
        title: 'Discord community beitreten',
        description: 'Mit anderen Entwicklern vernetzen, um Lösungen zu finden',
        button: 'Beitreten',
    },
    github: {
        title: 'Kommunikation auf GitHub',
        description: 'Erstellen Sie ein Problem und reichen Sie es bei GitHub ein',
        button: 'Öffnen',
    },
    email: {
        title: 'Unterstützung per E-Mail kontaktieren',
        description: 'Senden Sie uns eine E-Mail für weitere Informationen und Hilfe',
        button: 'Senden',
    },
    reserve: {
        title: 'Reservieren Sie Ihre Zeit beim Seitrace-Team',
        description: 'Buchen Sie eine Sitzung für einen Live-Chat',
        button: 'Buch',
    },
};
export default Object.freeze(contact);
